<script setup lang="ts">
const props = defineProps({
    blog: Object,
    returnTo: {
        type: String,
        default: null
    }
});

function handleClick(event) {
    if (event.button) {
        return window.open(props.blog._path, '_blank');
    }

    const router = useRouter()
    router.push({
        path: props.blog._path,
        query: {
            returnTo: props.returnTo
        }
    })
}
</script>

<template>
    <div
            class="card"
            @click.stop="handleClick"
            @click.middle.stop="handleClick"
    >
        <div class="card-image">
            <figure class="image is-3by2 is-fullwidth">
                <nuxt-img
                        :src="`/images/covers/${blog.coverImage}`"
                        :alt="blog.title"
                        sizes="xs:770px md:600px"
                />
            </figure>
        </div>
        <div class="card-content">
            <div class="media">
                <div class="media-content">
                    <p class="title is-5">
                        <NuxtLink @click.middle.stop="" class="revert" :href="blog._path">{{ blog.title }}</NuxtLink>
                    </p>
                </div>
            </div>
            <div class="content">
                <div class="description">
                    {{ blog.description }}
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "scss/variables";
@import "bulma/sass/utilities/mixins";

.card {
  height: 100%;
  cursor: pointer;
  transition: 0.3s;

  .title {
    line-height: 1.4;
  }

  &:hover {
    @include tablet {
      transform: scale(1.04);
    }

    p.title {
      a {
        color: $pink;
      }
    }
  }

  .description {
    position: relative;
    height: 96px;
    overflow: hidden;

    &:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 70%;
      height: 1.2em;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }
  }
}

</style>


